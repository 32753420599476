import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Button from './button';

import colors from '../config/color';
import CircleNumber from './circleNumber';

const url = process.env.GATSBY_API_URL;

const Coupon = ({ location }) => {
  const { platform, partnerCode } = location.state || {};
  const [couponCode, setCouponCode] = useState('');
  if (typeof window !== 'undefined') {
    if (!_.isNumber(platform) || !partnerCode) {
      window.location.href = '/account/login/';
    }
  }
  const getCoupon = async () => {
    await fetch(
      `${url}/api/v2/official/coupon?platform=${platform}&partnerCode=${partnerCode}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'accept-language': 'zh',
        },
      },
    )
      .then(async result => {
        const jsonResult = await result.json();
        setCouponCode(jsonResult.data.code);
      })
      .catch(error => {});
  };

  useEffect(() => {
    getCoupon();
  }, []);

  return (
    // <Layout showHeader={true} showHero={false}>
    <>
      <div className="success-content">
        <h1>註冊/登入完成</h1>

        <div className="text-content">
          <CircleNumber text="1" />
          <div>
            <p>請下載 HereHear 聽聽 App</p>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.Bamboodd.HereHearV2&hl=zh_TW">
                <img src="/images/google@3x.png"></img>
              </a>
            </div>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="2" />
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <p>請點擊複製優惠碼</p>
            <input
              type="text"
              id="coupon-text"
              className="coupon-text"
              value={`${couponCode}`}
            ></input>
            <div className="coupon-input">
              <Button
                text="複製優惠碼"
                onClick={() => {
                  console.log('!!!!!');
                  document.getElementById('coupon-text').select();
                  document.execCommand('copy');
                }}
              />
            </div>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="3" />
          <div>
            <p>點選進入推薦活動</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s1.jpg"
            ></img>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="4" />
          <div>
            <p>點選有鎖的內容</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s2.jpg"
            ></img>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="5" />
          <div>
            <p>點選 3 個月方案右方『購買』</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s3.jpg"
            ></img>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="6" />
          <div>
            <p>點選 G Pay(信用卡號)，改變付款方式</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s4.jpg"
            ></img>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="7" />
          <div>
            <p>點選『兌換代碼』</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s5.jpg"
            ></img>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="8" />
          <div>
            <p>{`點選下方『兌換』，貼上代碼 ${couponCode}`}</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s6.jpg"
            ></img>
          </div>
        </div>

        <div className="text-content">
          <CircleNumber text="9" />
          <div>
            <p>點選下方『繼續』，完成兌換</p>
            <img
              className="screen-image"
              src="/images/androidCoupon/s7.jpg"
            ></img>
          </div>
        </div>
      </div>

      <style jsx>{`
        .success-content {
          margin: 20px 16px 50px 16px;
          min-width: 343px;
        }

        hr {
          border: none;
          height: 1px;
          background-color: #e0e0e0;
          min-width: 343px;
          margin: auto;
          margin: 20px 16px 50px 16px;
        }

        h1 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 28px;
          line-height: 1.33;
          margin: 32px 0 32px 58px;
        }

        .text-content {
          margin: 0 20px 20px 20px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 16px;
          line-height: 1.5;
          margin-left: 8px;
        }

        .lighted-purple {
          color: ${colors['bright-purple']};
          font-size: 16px;
        }

        .min-text {
          margin-top: 3px;
          font-size: 12px;
        }

        .lighted-pink {
          margin-top: 3px;
          color: ${colors['bright-pink']};
          font-size: 12px;
        }

        img {
          margin: 8px 6px;
          width: 114px;
          height: 34px;
        }

        .screen-image {
          width: 100%;
          height: auto;
          max-width: 250px;
          border: #444 1px solid;
        }

        .coupon-input {
          margin-top: 5px;
        }

        .coupon-text {
          font-size: 16px;
          font-family: SFProDisplay-Regular;
          color: ${colors['bright-pink']};
          border: 1px solid #707070;
          border-radius: 5px;
          text-align: center;
          height: 30px;
          width: calc(100%);
        }

        @media (min-width: 768px) {
          .content {
            display: flex;
            justify-content: center;
          }

          .success-content {
            margin: 20px 60px 50px 60px;
          }

          hr {
            margin: 20px 0 0 0;
          }

      `}</style>
    </>
  );
};

export default Coupon;
