import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import colors from '../config/color';
import CircleNumber from './circleNumber';

const Coupon = ({ location }) => {
  return (
    <>
      <div className="success-content">
        <h1>註冊/登入完成</h1>

        <div className="text-content">
          <CircleNumber text="1" />
          <div>
            <p>請下載 HereHear 聽聽 App</p>
            <div>
              <a href="https://apps.apple.com/tw/app/herehear-%E8%81%BD%E8%81%BD/id1479546405">
                <img src="/images/apple@3x.png"></img>
              </a>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .success-content {
          margin: 20px 16px 50px 16px;
          min-width: 343px;
        }

        hr {
          border: none;
          height: 1px;
          background-color: #e0e0e0;
          min-width: 343px;
          margin: auto;
          margin: 20px 16px 50px 16px;
        }

        h1 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 28px;
          line-height: 1.33;
          margin: 32px 0 32px 58px;
        }

        .text-content {
          margin: 0 20px 20px 20px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 16px;
          line-height: 1.5;
          margin-left: 8px;
        }

        .lighted-purple {
          color: ${colors['bright-purple']};
          font-size: 16px;
        }

        .min-text {
          margin-top: 3px;
          font-size: 12px;
        }

        .lighted-pink {
          margin-top: 3px;
          color: ${colors['bright-pink']};
          font-size: 12px;
        }

        img {
          margin: 8px 6px;
          width: 114px;
          height: 34px;
        }

        .screen-image {
          width: 100%;
          height: auto;
          border: #444 1px solid;
        }

        .coupon-input {
          margin-top: 5px;
        }

        .coupon-text {
          font-size: 16px;
          font-family: SFProDisplay-Regular;
          color: ${colors['bright-pink']};
          border: 1px solid #707070;
          border-radius: 5px;
          text-align: center;
          height: 30px;
          width: calc(100%);
        }

        @media (min-width: 768px) {
          .content {
            display: flex;
            justify-content: center;
          }

          .success-content {
            margin: 20px 60px 50px 60px;
          }

          hr {
            margin: 20px 0 0 0;
          }

      `}</style>
    </>
  );
};

export default Coupon;
