import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import AndroidCoupon from '../../components/coupon.android';
import IOSCoupon from '../../components/coupon.ios';

const Coupon = ({ location }) => {
  let iOS = false;
  if (typeof window !== 'undefined') {
    iOS =
      /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
  }
  return (
    // <Layout showHeader={true} showHero={false}>
    <Layout showHamburgerBtn={false} showAccountBtn={false} showHeader={true}>
      {/* TODO: SEO */}
      <SEO title="Here Hear 聽聽" />
      <hr />
      <div className="content">
        {iOS ? (
          <IOSCoupon location={location} />
        ) : (
          <AndroidCoupon location={location} />
        )}
      </div>

      <style jsx>{`
        @media (min-width: 768px) {
          .content {
            display: flex;
            justify-content: center;
          }
        }
      `}</style>
    </Layout>
  );
};

export default Coupon;
